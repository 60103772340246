
document.addEventListener("DOMContentLoaded", function(){

  // navigation
  var navTrigger = document.getElementsByClassName('nav-trigger')[0],
      body = document.getElementsByTagName('body')[0];
  navTrigger.addEventListener('click', toggleNavigation);
  function toggleNavigation(event) {
    event.preventDefault();
    body.classList.toggle('nav-open');
  };

  // FLICKITY
  var flkty = new Flickity( '.talent-carousel', {
    prevNextButtons: false,
    pageDots: true,
    selectedAttraction: 0.2,
    friction: 0.8,
    autoPlay: 4500,
    wrapAround: true
  });


});



if (module.hot){
  module.hot.accept();
}
